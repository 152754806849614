import { ImCross } from 'react-icons/im'
import styles from './ModalOrderSpare.module.css'
import { useState } from 'react'
import { useOrderSpareAvailabilityMutation } from '../../../redux/searchSpares/apiSearchSpares'
import Loader from '../../loader/Loader'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { selectSearchedFinalSpare } from '../../../redux/searchSpares/sparesSlice'
import { useGetOrderPermissionMutation } from '../../../redux/clientData/apiClientData'
import CopyToClipboard from 'react-copy-to-clipboard'
import { MdCopyAll } from 'react-icons/md'

const ModalOrderSpare = ({
  spareToOrder,
  setSpareToOrder,
  handleSearchFinal,
  isSpareToOrderInternal,
}) => {
  const [copied, setCopied] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [qtyInput, setQtyInput] = useState(1)
  const [isNoReturnChecked, setIsNoReturnChecked] = useState(
    spareToOrder[0].isReturnable
  )
  const [noReturnCheckedOrderAttempt, setNoReturnCheckedOrderAttempt] =
    useState(false)

  const [getOrderPermission, { isLoading: isLoadingOrderPermission }] =
    useGetOrderPermissionMutation()

  const searchedSpare = useSelector(selectSearchedFinalSpare)

  const [reserveSpare, { isLoading: isLoadingReserveSpare }] =
    useOrderSpareAvailabilityMutation()

  const handleQtyInput = (e) => {
    if (e.target.value === '') {
      setQtyInput('')
      return
    }
    if (e.target.value > spareToOrder[0].availability) {
      setQtyInput(spareToOrder[0].availability)
      return
    }
    if (e.target.value % 1 !== 0) {
      setQtyInput(Math.floor(e.target.value))
      return
    }
    if (e.target.value < 1) {
      setQtyInput(1)
      return
    }
    if (e.target.value.includes('.')) {
      setQtyInput(e.target.value.split('.')[0])
      return
    }
    if (e.target.value.includes(',')) {
      setQtyInput(e.target.value.split(',')[0])
      return
    }
    if (e.target.value.includes('-')) {
      setQtyInput(e.target.value.split('-')[0])
      return
    }
    if (e.target.value.includes('+')) {
      setQtyInput(e.target.value.split('+')[0])
      return
    }
    if (e.target.value.includes('e')) {
      setQtyInput(e.target.value.split('e')[0])
      return
    }
    if (e.target.value.includes('E')) {
      setQtyInput(e.target.value.split('E')[0])
      return
    }
    if (e.target.value.includes(' ')) {
      setQtyInput(e.target.value.split(' ')[0])
      return
    }
    setQtyInput(e.target.value)
  }

  const handleReserveSpare = async (spareID, price, qty) => {
    setIsDisabledButton(true)
    const orderPermission = await getOrderPermission().unwrap()
    if (orderPermission?.orderPermission === false) {
      toast.error('Немає прав на замовлення, зверніться до вашого менеджера...')
      setSpareToOrder([])
      return
    }
    if (!isNoReturnChecked) {
      toast.error('Не погоджено умови повернення')
      setNoReturnCheckedOrderAttempt(true)
      return
    }
    setNoReturnCheckedOrderAttempt(false)
    const qtyPattern = /^[0-9]+$/
    if (!qtyPattern.test(qty)) {
      toast.error('Кількість не була вказана коректно')
      setQtyInput(1)
      return
    }
    if (isSpareToOrderInternal) {
      const body = {
        spareID,
        price,
        qty,
        isInternal: true,
      }
      try {
        const response = await reserveSpare(body).unwrap()
        if (response.message === 'Spare reserved') {
          toast.success('Запчастина зарезервована')
          setSpareToOrder([])
          handleSearchFinal(
            searchedSpare.analogueID,
            searchedSpare.brand,
            searchedSpare.code,
            searchedSpare.spareID
          )
        } else {
          toast.error('Помилка резервування запчастини')
          handleSearchFinal(
            searchedSpare.analogueID,
            searchedSpare.brand,
            searchedSpare.code,
            searchedSpare.spareID
          )
        }
      } catch (error) {
        toast.error('Помилка резервування запчастини')
        handleSearchFinal(
          searchedSpare.analogueID,
          searchedSpare.brand,
          searchedSpare.code,
          searchedSpare.spareID
        )
      }
    } else {
      const body = {
        spareID,
        price,
        qty,
        isInternal: false,
      }
      try {
        const response = await reserveSpare(body).unwrap()
        if (response.message === 'Spare reserved') {
          toast.success('Запчастина замовлена')
          setSpareToOrder([])
          handleSearchFinal(
            searchedSpare.analogueID,
            searchedSpare.brand,
            searchedSpare.code,
            searchedSpare.spareID
          )
        } else {
          toast.error('Помилка резервування запчастини')
          handleSearchFinal(
            searchedSpare.analogueID,
            searchedSpare.brand,
            searchedSpare.code,
            searchedSpare.spareID
          )
        }
      } catch (error) {
        toast.error('Помилка резервування запчастини')
        handleSearchFinal(
          searchedSpare.analogueID,
          searchedSpare.brand,
          searchedSpare.code,
          searchedSpare.spareID
        )
      }
    }
  }

  const onCopy = (text, result) => {
    setCopied(result)
    if (result) {
      toast.success('Артикул скопійовано')
    } else {
      toast.error('Не вдалося скопіювати артикул')
    }
  }

  return (
    <>
      {isLoadingReserveSpare && <Loader />}
      {isLoadingOrderPermission && <Loader />}
      <div className="overlayCont">
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div>
              {isSpareToOrderInternal ? 'Резерв запчастини' : 'Під замовлення'}
            </div>
            <div
              onClick={() => setSpareToOrder([])}
              className={styles.closeIcon}
            >
              <ImCross />
            </div>
          </div>
          <div className={styles.orderContainer}>
            <div className={styles.infoText}>Бренд</div>
            <div className={styles.spareInfoContainer}>
              <div className={styles.infoText}>{spareToOrder[0].brand}</div>
            </div>
          </div>
          <div className={styles.orderContainer}>
            <div className={styles.infoText}>Артикул</div>
            <div className={styles.spareInfoContainer}>
              <div className={styles.infoText}>
                {spareToOrder[0].code}{' '}
                <CopyToClipboard text={spareToOrder[0].code} onCopy={onCopy}>
                  <span
                    onClick={(e) => e.stopPropagation()}
                    className={styles.copyIconSpan}
                  >
                    <MdCopyAll />
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          {isSpareToOrderInternal && (
            <div className={styles.orderContainer}>
              <div className={styles.infoText}>Наявність</div>

              <div className={styles.spareInfoContainer}>
                <div className={styles.infoText}>
                  {spareToOrder[0].availability} шт
                </div>
              </div>
            </div>
          )}
          <div className={styles.orderContainer}>
            <div className={styles.infoText}>Кількість</div>
            <div className={styles.spareInfoContainer}>
              <div className={styles.infoText}>
                <input
                  className={styles.qtyInput}
                  type="number"
                  value={qtyInput}
                  onChange={handleQtyInput}
                />
              </div>
            </div>
          </div>
          <div className={styles.orderContainer}>
            <div className={styles.infoText}>Ціна</div>
            <div className={styles.spareInfoContainer}>
              <div className={styles.infoText}>{spareToOrder[0].price}</div>
            </div>
          </div>
          <hr className={styles.modalHR} />
          {!spareToOrder[0].isReturnable && (
            <div className={styles.noReturnAgreementContainer}>
              <input
                onChange={() => setIsNoReturnChecked(!isNoReturnChecked)}
                type="checkbox"
                value={isNoReturnChecked}
              />

              <span
                className={
                  noReturnCheckedOrderAttempt
                    ? styles.noReturnTextAttempt
                    : styles.noReturnText
                }
              >
                Я ознайомлений та погоджуюсь з тим, що ця позиція не підлягає
                поверненню.
              </span>
            </div>
          )}
          <div className={styles.btnsContainer}>
            <div className={styles.infoBtnContainer}>
              <button
                onClick={() =>
                  handleReserveSpare(
                    spareToOrder[0].spareID,
                    spareToOrder[0].price,
                    qtyInput
                  )
                }
                disabled={isDisabledButton}
                className={styles.infoBtn}
              >
                {isSpareToOrderInternal ? 'В резерв' : 'Замовити'}
              </button>
            </div>
            <div className={styles.infoBtnContainer}>
              <button
                onClick={() => setSpareToOrder([])}
                className={styles.infoBtn}
              >
                Відмінити
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalOrderSpare
